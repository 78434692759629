import React from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import '../Home Sections/HomeSection.scss';

export default function HomeSection() {
  return (
    <div className='slider-container'>
      <CarouselProvider
        naturalSlideWidth={800}
        naturalSlideHeight={250}
        totalSlides={3}
      >
        <Slider className='slider'>
          {Array.from({ length: 3 }).map((_, index) => (
            <Slide index={index} key={index}>
              <div className='slide-content'>
                <div className='text-container'>
                  <h1>THE</h1>
                  <h1>MOFO GOATS</h1>
                  <h2>MEDIA DAO</h2>
                </div>
                <div className='slider-image'>
                  <img src="./images/goats-1.png" alt="Goats" />
                </div>
              </div>
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </div>
  );
}
