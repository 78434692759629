import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ToastPopup.scss'; 

export const showToast = (message, type = "default") => {
    const options = {
        position: "top-center",
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        pauseOnHover: false,
        theme: "light",
        // Apply custom classes for individual toasts as well
        toastClassName: "custom-toast-body",
        progressClassName: "custom-toast-progress-bar"
    };

    if (type === "success") {
        toast.success(message, options);
    } else if (type === "error") {
        toast.error(message, options);
    } else {
        toast(message, options);
    }
};

// Component to insert in root of app
const ToastPopup = () => {
    return (
        <ToastContainer 
            toastClassName="custom-toast-body"
            progressClassName="custom-toast-progress-bar"
        />
    );
};

export default ToastPopup;
