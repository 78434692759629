import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ApprovalModalOpen = ({ isOpen, walletInfo, onApprove, onDeny }) => {
    
    return (
        <Modal isOpen={isOpen} toggle={onDeny}>
            { walletInfo === 'Goat not found' ? (
                <>
                    <ModalHeader>No Wallet Found</ModalHeader>
                    <ModalBody>
                        <p>Goat ID didn't exist.</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' onClick={onDeny}>
                            Close
                        </Button>
                    </ModalFooter>
                </>
                
            ) : (
                <>
                    <ModalHeader>Wallet Found!</ModalHeader>
                    <ModalBody>
                        Wallet Address: <b style={{ fontSize: '11pt'}}>{ walletInfo?.wallet }</b>
                        <br />
                        <br />
                        <b>Goats Owned: { walletInfo?.count }</b>
                        <div style={{ height: '12rem', overflowY: 'scroll'}}>
                            {walletInfo?.owned_goats?.map((goatId, index) => (
                                <div key={ index }>{ goatId }</div>
                            ))}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' onClick={ onApprove }>
                            Approve
                        </Button>
                        <Button color='secondary' onClick={ onDeny }>
                            Deny
                        </Button>
                    </ModalFooter>
                </>
            )}
        </Modal>
    )
}

export default ApprovalModalOpen;