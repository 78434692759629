import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Home Sections/GoatPaperSection.scss';

export default function GoatPaperSection() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/goatpaper');
    }

    return (
        <>
            <div className='section2'>
                <div className='gp-box-container'>
                    <div className='gp-inner-box'>
                        <div className='gp-img-wrap'>
                            <img className='gp-sp-img' src="./images/goats-3.png" alt="image"/>
                        </div>
                        <div className='gp-txt-wrap'>
                            <p className='gp-sp-text large-text'>Asset backed generative NFT!</p>
                        </div>
                    </div>
                </div>
                <div className='gp-box-container'></div>
                    <div className='gp-inner-box'>
                        <div className='gp-img-wrap'>
                            <img className='gp-sp-img' src="./images/goats-4.png" alt="image" style={{ height: '12rem', top: '-2rem'}} />
                        </div>
                        <div className='gp-txt-wrap'>
                            <p className='gp-sp-text large-text'>The Goats DAO is a game changer!</p>
                        </div>
                        <button className='gp-btn' onClick={handleClick}>READ GOATPAPER</button>
                </div>
            </div>
        </>

    )
}
