import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./AxiosInstance";
import { showToast } from "../utils/Toasts/ToastPopup";
/**
 * Manage and share session state across components
 */
const AuthContext = createContext();

// hook to utilize across components
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    // check session validity
    useEffect(() => {
        const checkSession = async () => {
            console.log('checking session...', isAuthenticated);
            if (!isAuthenticated) {
                console.log("No active session.");
                return;
            }
            try {
                console.log("making request");
                const response = await axios.get('/api/v1/user/session');
                if (response && response.status === 200) {
                    setIsAuthenticated(true);
                }
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    setIsAuthenticated(false);
                    localStorage.setItem('showTimeoutToast', 'true');
                    navigate('/');
                    window.location.reload();
                } else {
                    console.error('Error with session check:', e.message || e);
                    showToast("An unexpected error occurred.", "error");
                }
            }
        };
    
        
        const interval = setInterval(checkSession, 360000); 
        return () => clearInterval(interval);
    }, [isAuthenticated]); 
    

    // shpw timeout error toast msg
    useEffect(() => {
        if (localStorage.getItem('showTimeoutToast') === 'true') {
            showToast("Your session has timed out, please log back in.", "error");
            localStorage.removeItem('showTimeoutToast');
        }
    }, []);
    

    // handler for login/logout actions
    const setAuthStatus = (status) => {
        setIsAuthenticated(status);
        if(!status) {
            //setIsAuthenticated(false)
            localStorage.setItem('showTimeoutToast', 'true');
            navigate('/');
            window.location.reload();
        }
    }

    // persist through loading and navigation
    useEffect(() => {
        const storedAuthStatus = localStorage.getItem('isAuthenticated') === 'true';
        console.log('curr auth status... ', storedAuthStatus);
        setIsAuthenticated(storedAuthStatus)
    }, []);

    // track session status and keep updated
    useEffect(() => {
        console.log("Session status: ", isAuthenticated);
        localStorage.setItem('isAuthenticated', isAuthenticated);
    }, [isAuthenticated]);


    // ping server for health check
    const checkHealth = async () => {
        try {
            const response = await axios.get('/api/v1/server/health-check');
            // console.log("hc: ", response.data);
        } catch (error) {
            console.error("Server check failed: ", error);
        }
    }

    useEffect(() => {
        const interval = setInterval(checkHealth, 600000)
        return () => clearInterval(interval);
    }, [])

    return (
        <AuthContext.Provider value={{ isAuthenticated, setAuthStatus }}>
            {children}
        </AuthContext.Provider>
    )
}