import React from 'react'
import { Link } from 'react-scroll'
import './Navigation.scss'

export default function Navigation() {
  const offsetValue = 70;
  return (
    <div className="navigation">
        <ul >
          <li>
            <Link to="section1" spy={true} smooth={true} duration={500} >GOAT STORY</Link>
          </li>
          <li>
            <Link to="section2" spy={true} smooth={true} duration={500} offset={offsetValue}>GOAT PAPER</Link>
          </li>
          <li>
            <Link to="section3" spy={true} smooth={true} duration={500} >TEAM</Link>
          </li>
          <li>
            <Link to="section4" spy={true} smooth={true} duration={500} >FAQ</Link>
          </li>
        </ul>
    </div>
  )
}