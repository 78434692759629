import React, { useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import ResponsiveContainer from './utils/ResponsiveContainer';
import { AuthProvider } from './services/AuthContext';
import ToastPopup from './utils/Toasts/ToastPopup';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import GoatPaper from './pages/GoatPaper';
import Footer from './components/Footer';

export default function App() {
  const clientID = '309313235732-epuddtr7kpfimua022eg542degktjbsq.apps.googleusercontent.com';

  useEffect(() => {
    const clientTimezone = getClientTimezone();
    console.log('Client Timezone Information:', clientTimezone);
  }, []);

  function getClientTimezone() {
    const timezoneOffset = new Date().getTimezoneOffset();
    const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return {
        timezoneOffset,
        timezoneName
    };
  }

  if(!clientID) {
    return null;
  }

  return (
    // wrap auth provider around our application
    <AuthProvider>
      <ResponsiveContainer>
      <ToastPopup />
      <GoogleOAuthProvider clientId={`${clientID}`}>
      <Navbar className="nav"/>
      <Routes>
        <Route path="/" element={ <Home /> }/>
        <Route path="/goatpaper" element={ <GoatPaper /> }/>
      </Routes>
      <Footer />
      </GoogleOAuthProvider>
      </ResponsiveContainer>
    </AuthProvider>

  );
}