import React from 'react'
import styled from 'styled-components'
import '../Home Sections/TeamSection.scss'

export default function TeamSection() {
    return (
        <div className='section3'>
            <div className='team-pyramid'>
                <h2 className="team-text">The Goat Team</h2>
                    <div className='row'>
                        <TeamImageWrapper name="Colin" position="Founder">
                            <img className='team-img' src="./images/team/Collin-Goat.png" />
                        </TeamImageWrapper>
                        
                        <TeamImageWrapper  name="Garnet" position="Founder">
                            <img className='team-img' src="./images/team/garnetavatar_cu.png" />
                        </TeamImageWrapper>

                        <TeamImageWrapper name="Dr. T" position="Ministry of Code">
                            <img className='team-img' src="./images/team/Dr.T.png" />
                        </TeamImageWrapper>

                        <TeamImageWrapper  name="Jasmine" position="Software Engineer">
                            <img className='team-img' src="./images/team/jasmine.png" />
                        </TeamImageWrapper>
                    </div>
                    <div className='row'>
                        {/* More rows with increasing number of TeamImageWrapper components */}
                        <TeamImageWrapper name="Phil" position="Community Security">
                            <img className='team-img' src="./images/team/phil.png" />
                        </TeamImageWrapper>

                        <TeamImageWrapper name="Brian" position="VR Community">
                            <img className='team-img' src="./images/team/Brian.png" />
                        </TeamImageWrapper>

                        <TeamImageWrapper name="David" position="Legal">
                            <img className='team-img' src="./images/team/david.png" />
                        </TeamImageWrapper>
                    </div>
            </div>
        </div>
    );
}

const TeamImageWrapper = ({ name, position, children }) => (
    <div className='wrap'>
        {children}
        <div className='team-desc'>
            <div className='team-name'>{name}</div>
            <div className='team-pos'>{position}</div>
        </div>
    </div>
);