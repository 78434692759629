import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useUserStore = create(persist(
  (set) => ({
    userData: {},
    setUserData: (userData) => set({ userData }),
  }),
  {
    name: "user-storage", // unique name for the storage (used as the key in localStorage)
    getStorage: () => localStorage, // specifies localStorage as the storage option
  }
));

export default useUserStore;
