import axios from 'axios';

const AxiosInstance = axios.create({
    baseURL: 'https://api.goatsdao.com', //'http://localhost:8000', 
    withCredentials: true,
    headers: {
        'Access-Control-Allow-Headers': 'Cookie, Set-Cookie',
        'Access-Control-Expose-Headers': 'Set-Cookie',
    },
});

export default AxiosInstance;