import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, TextField, Tooltip, styled} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'react-phone-number-input/style.css';

import axios from '../../services/AxiosInstance';
import WalletApprovalModal from './WalletApprovalModal';
import { useAuth } from '../../services/AuthContext';
import { showToast } from '../../utils/Toasts/ToastPopup';
import useUserStore from '../../stores/userStore';
import CustomTextField from '../Form Fields/CustomTextField';
import CustomSocialsField from '../Form Fields/CustomSocialsField';

// phone number validator
Yup.addMethod(Yup.string, 'phone', function (errorMessage) {
  return this.test('phone', errorMessage, function (value) {
    const { path, createError } = this;
    // Replace this regex with the one that matches your desired phone number format
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    return (
      (!value || phoneRegex.test(value)) || 
      createError({ path, message: errorMessage })
    );
  });
});

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email format').required('Required'),
  phone: Yup.string().phone('Invalid phone number').required('Required'),
  wallet: Yup.string(),
  socials: Yup.array(),
  username: Yup.string(),
  // TODO: 
  // add validation that city,state,postCode, and country must be provided
  // or no address data at all
  address: Yup.string(),
  city: Yup.string().required('Must provide City'),
  state: Yup.string().required('Must provide State'),
  postalCode: Yup.string().required('Must provide Post Code'),
  country: Yup.string().required('Must provide Country')
});

const goatIdSchema = Yup.object().shape({
  goatid: Yup.number().typeError("Goat id must be a number")
})

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#142949',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 420, // Adjust the size as needed
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    padding: theme.spacing(2),
    textAlign: 'center', // Center text
    transform: 'translate(0rem, 0rem) !important'
  },
  [`& .MuiTooltip-tooltip .tooltip-image`]: {
    maxWidth: '100%', // Ensure the image is responsive and centers itself
    display: 'block', // Images are inline by default
    margin: '0 auto', // Center the image
  }
}));


export default function RegisterModal({ isModalOpen, toggleModal }) {
  const {setAuthStatus} = useAuth();
  const userData = useUserStore((state) => state.userData);
  
  const [ goatId, setGoatId ] = useState('');
  const [ walletInfo, setWalletInfo ] = useState(null);
  const [socials, setSocials ] = useState([]);

  const [walletApprovalModalOpen, setWalletApprovalModalOpen] = useState(false);

  const [ loading, setLoading ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  // NOTE: added to rectify scenario where 'e' is provided in number input.
  // (treated a scientific notation exponent ex. 1e10 -> 1 x 10^10)
  // input that caught this issue '212e333'
  const handleNumberFieldChange = (event) => {
    const value = event.target.value;
    // replace non-digit characters
    const cleanedValue = value.replace(/\D/g,'');
    setGoatId(cleanedValue);
  }

  const handleGoatIdCheck = async ( id ) => {
    setErrorMessage('');
    setLoading(true);
    try {
      await goatIdSchema.validate({ goatid: id });
      const response = await axios.get(`/api/v1/wallet/member/${id}`);
      // console.log("wallet data: ", response.data)
      setWalletInfo(response.data);
      setWalletApprovalModalOpen(true);

    } catch (e) {
      if (e.response && e.response.status && e.response.status === 401) {
        setAuthStatus(false);
      } else if (e instanceof Yup.ValidationError) {
        setErrorMessage(e.message);
      } else {
        console.error("Issue checking goat id: ", e)
      }
    } finally {
      setLoading(false);
    } 
  }

  const handleWalletApproval = ( walletAddress, setFieldValue ) => {
    setFieldValue('wallet', walletAddress);
    setWalletApprovalModalOpen(false);
  }

  const handleWalletDenial = () => {
    setWalletApprovalModalOpen(false); // Close modal without setting the wallet field
  };

  const handleAddSocial = (socialType, username) => {
    const baseUrls = {
      Twitter: 'https://twitter.com/',
      YouTube: 'https://youtube.com/@',
      Discord: 'https://discord.com/users/',
      Instagram: 'https://instagram.com/',
      TikTok: 'https://tiktok.com/@',
      Twitch: 'https://m.twitch.tv/',
    };

    const urlSuffix = socialType === 'Twitch' ? `${username}/home` : username;

    const newSocial = {
      platform: socialType,
      username: username,
      url: `${baseUrls[socialType]}${urlSuffix}`
    };

    setSocials([...socials, newSocial]);
  }

  const handleRemoveSocial = (indexToRemove) => {
    setSocials(socials.filter((_, index) => index !== indexToRemove));
  }

  const handleSubmit = async ( values ) => {
    const payload = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone,
      wallet: values.wallet,
      address: values.address.toUpperCase(),
      city: values.city.toUpperCase(),
      state: values.state.toUpperCase(),
      postal_code: values.postalCode,
      country: values.country.toUpperCase(),
      socials: socials
    };

    try {
      await axios.post('/api/v1/user/register', payload);
      showToast("Registration was successfull!","success");
      toggleModal();

    } catch (e) {
      if (e.response.status && e.response.status === 401) {
        setAuthStatus(false);
      }
    }
  }

  const toolTipFindGoatId = (
    <div>
      <img
        src='./images/find_goat_id_example.png'
        alt='Where To Find GoatId Example'
        style={{ width: '100%', height: 'auto', color: 'white'}}
      />
      <p>Where to locate your Goats Id</p>
    </div>
  )

  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Register</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{ firstName: userData?.first_name || '', lastName: userData?.last_name || '',
           email: userData?.email || '', phone: userData?.phone || '', goatid: '', wallet: userData?.wallet || '', 
           socials: [], username: '', address: '', city: '', state: '', postalCode: '', country: ''}}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            //console.log("submit")
            // Handle form submission
            setSubmitting(true);
            handleSubmit(values);
            setSubmitting(false);
          }}
          validator={() => ({})}
        >
          {({ values, setFieldValue, isSubmitting, errors, touched }) => (
            <>
              <Form>

                <CustomTextField label='First Name' name='firstName' disabled={!!userData.first_name} />
                <CustomTextField label='Last Name' name='lastName' disabled={!!userData.last_name} />
                <CustomTextField label='Email' name='email' type='email' disabled={!!userData.email} />
                <CustomTextField label='Mobile Phone' name='phone' type='tel' />
                {/* <CustomPhoneField 
                    label='Phone Number' 
                    initialCountry="US"
                    value={values.phone}
                     /> */}
                <StyledTooltip 
                title={toolTipFindGoatId} point>
                <TextField
                    label='Goat ID (Do not include hashtag "#")'
                    value={goatId}
                    disabled={loading}
                    onChange={handleNumberFieldChange}
                    fullWidth
                    margin='normal'
                    error={!!errorMessage}
                    helperText={
                    <span style={{color:'green'}}>
                      <p style={{color: 'red'}}>{errorMessage}</p>
                      Enter one of your owned goat ids to validate 
                      membership and tie your wallet to your profile.<br/>
                      <strong style={{color: 'black'}}>This is optional, you will have other ways to verify your membership in the future!</strong>
                    </span>
                    }
                  />
                  </StyledTooltip>
                  <Button onClick={() => handleGoatIdCheck(goatId, setFieldValue)} type='button' disabled={!goatId}>
                    Check
                  </Button>
                <CustomTextField label='Wallet' name='wallet' type='wallet' value={walletInfo?.wallet || ''}/>

                <CustomSocialsField label='Social Media'
                  onAddSocial={handleAddSocial}
                  socialOptions={[
                    { label: 'Twitter', value: 'Twitter' },
                    { label: 'YouTube', value: 'YouTube' },
                    { label: 'Discord', value: 'Discord' },
                    { label: 'Instagram', value: 'Instagram' },
                    { label: 'TikTok', value: 'TikTok' },
                    { label: 'Twitch', value: 'Twitch' }
                ]}/>
                {socials.length > 0 ? (
                  socials.map((social, index) => (
                    <div key={index}>
                      {social.platform}: {social.username} <button type="button" onClick={() => handleRemoveSocial(index)}>X</button>
                    </div>
                  ))
                  ) : (
                    <p>No socials added yet.</p>
                  )}

                <CustomTextField label='Address' name='address' type='address' />
                <CustomTextField label='City' name='city' type='city' />
                {touched.city && errors.city && !errors.address && (
                  <div style={{ color: 'red' }}>{errors.city}</div>
                )}
                <CustomTextField label='State' name='state' type='state' />
                <CustomTextField label='Zip Code' name='postalCode' type='postalCode' />
                <CustomTextField label='Country' name='country' type='country' />

                <Button color='primary' 
                  variant='contained'
                  type='submit' 
                  disabled={isSubmitting}
                  sx={{ fontSize: '1.5rem', width: '100%'}}
                  >
                  Register
                </Button>
              </Form>
                <WalletApprovalModal
                  isOpen={ walletApprovalModalOpen }
                  walletInfo={ walletInfo }
                  onApprove={() => handleWalletApproval( walletInfo.wallet, setFieldValue )}
                  onDeny={ handleWalletDenial }
                />
            </>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}