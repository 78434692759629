import React from 'react';
import './GoatPaper.scss';

// This could be a component for displaying images
const ImageSection = ({ src, alt }) => (
  <div className="image-section">
    <img src={src} alt={alt} />
  </div>
);

// This could be a component for displaying lists with custom icons
const ListSection = ({ items }) => (
  <ul className="list-section">
    {items.map((item, index) => (
      <li key={index}>
        {/* Replace 'list-icon' with your actual icon */}
        <span className="list-icon">▫</span> {item}
      </li>
    ))}
  </ul>
);

function GoatPaper() {
  return (
    <div className="goat-paper">
      <header className="header-image">
        {/* You can change the 'header.jpg' to the path of your header image */}
        <ImageSection src="./images/goats-bg.png" alt="Header" />
      </header>
      <main className="content">
        <h1>Motivated and Focused Goats DAO</h1>
        {/* Intro section */}
        <h3>Join the GREATEST OF ALL TIME!</h3>
        <p>Here at the MoFo Goats, we are primarily 
            focused on empowering creators all over this world to 
            utilize Web3 Technologies and collaborate with each 
            other. They can connect and collaborate in real life, 
            get behind-the scenes looks at movies or music being 
            made by our community of content creators and industry 
            professionals - all thanks because they're holding a M
            oFo Goat NFT. NFT holders will have a voice and part 
            in the governance of our GOATS DAO by granting our Goat 
            holders governance rights as well voting privileges for
            decisions that affect them directly - like what gets 
            produced or where it's sold! Goat holders enjoy 
            commercial usage rights to their goat.
        </p>
        <h4>Table of Contents</h4>
        <ListSection items={[ 'Goats DAO', 'Proposals', 'Starwire Token (STR)', 
        ' Archer Goats', 'MoFo Goats', 'Roadcases', 'Auction', 'Secondary Sales', ' Summary' ]} />

        {/* Goats DAO Section */}
        <h3>Goats DAO</h3>
        <p>Manages the Goats NFT treasury via DAO proposals. 
            The treasury consists of 2,500 MoFo Goats and a quorum 
            of at least 2% goats is required for voting, with a 
            70% “FOR” required to approve a proposal. The voting 
            will be open for a week. The only action that can be 
            executed with a proposal is the transfer of X amount 
            of Goats NFT from the treasury to a desired address.<br/><br/>
            <b>1 Goat NFT = 1 Vote.</b>
        </p><br/>
       
       {/* Proposals Section */}
       <h3>Proposals</h3>
        <p>Submitting proposals to the Goats DAO Treasury requires holding at least 1 Goat NFT. 
            A proposal is one or more transactions to be executed by the DAO smart contract. 
            Proposals can range from funding addresses to more complex structures that interact 
            with other contracts.<br/>

        A more detailed guide to the proposal system will be provided in the future.<br/>

        <b>Starwire Token (STR)</b>
            The STR token is is an ERC-20 token used for Governance, Farming and Utility in the Goats DAO.
            Starwire is a global entertainment network that enables economic benefits through smart contract 
            automation, which allows artists to focus on their creative energy.
            STR tokens are allocated to:<br/>
        </p>
        <ListSection items={[ 'Liquidity Farms 31,000,000 (15.5%)', 'Goats DAO Members 160,000,000 (80%)', 
        'Starwire Token (STR)', 'Contribution to BEES DAO 4,000,000 (2%)', 'Management Team 5,000,000 (2.5%)' ]} />

        {/* Archer Goats Section */}
        <h3>Archer Goats</h3>
        <p>Archer Goats are custom hand painted by world famous artist Paul Archer. The Archer Goats will 
            play significant role in the Goats DAO ecosystem, Each Archer Goat will represent an iconic 
            entertainment venue from around the globe. There will only be 10 available in the public mint 
            and 140 allocated towards the Goat auction.
        </p><br/>
        <ImageSection src="./images/PaulArcherAirbrushing.png" alt="Archer" />
        <p>Archer is carved from the same chunk of originality as the bad boys of rock ’n’ roll; he’s loud, brash and full of zip. Not only that, he has countless tattoos and is a dead ringer for Tommy Lee of Mötley Crüe. Not a bad image to present when your clients include Ozzy Osbourne and Alice Cooper.

                Like the best of his kind, Archer appears stuck in a continuous state of performance. But if you focus on what’s real and what you can prove, it’s obvious Archer leads an extraordinary ordinary life.

                He was born in London, England on Christmas Day in 1959 (fun fact: Archer’s middle name is Noël) to a church minister father, who himself was born on All Angels Day, and a mother, Mary, who is blessed with the middle name Magdalene.

                Archer’s family came to Victoria Canada in 1970, When Archer graduated he was almost completely self-taught and began to emerge as an artist.

                Eventually, he settled in Vancouver’s West End and created with business partner Dave Coupland the airbrush mural company MAD Artists. The good times for Archer were in full swing by 1987. Archer recalled partying with Guns N’ Roses and Mötley Crüe at his condo, getting tons of work accomplished, hanging out with Johnny Depp during his 21 Jump Street tenure, and Steven Tyler of Aerosmith.

                After splitting with Coupland, Archer received a contract to paint, every month for a period of four years, a new 10-metre-square mural on the side of the C-FOX radio station building, to promote new albums being sold at HMV. Archer returned to England for six months. It was there he came in contact with Sharon Osbourne, wife of Ozzy, which led to work with a reunited Black Sabbath.

                Archer has a string of stories about the celebrities he’s either met or worked for, people like Alice Cooper, Rob Zombie, Sting, Gene Simmons and Lady Gaga

                There’s always an adventure around the corner, Archer said. He has painted buildings, tour buses, cars, Harley-Davidsons and surf boards, among other items. His favourites are always huge in scope.

                “The bigger the better, as far as I’m concerned. Give me a 20-story building as opposed to a canvas and I’ll take it. The feeling when you’re six, seven stories up a building and painting an eyeball the size of a Volkswagen ... working at that magnitude, it gives me goosebumps. I’m addicted to it, I have to paint every day or I get the shakes — literally.”
        </p><br/>

        {/* MoFo Goats Section */}
        <h3>MoFo Goats</h3>
        <p>The Motivated and Focused Goats in collaboration with the Starwire team come together to launch the 5000 MoFo Goats digital art collection of unique Goat NFTs randomly generated on the Ethereum Blockchain. Representing the passion, heart, talents, and confidence of musicians, artists, Filmmakers and. 1200 MoFo Goats reserved for marketing events, giveaways, the long-term health of Goats ecosystem as well as proven projects, leaders, and educators within financially distressed areas around the globe.

            The Mofo goats game has every goat embedded with around 32,000 STR tokens but watch out for the Chupacabra, the dread enemy of the goats. They come to steal the Goats treasure, if the goats don’t stop them they will make off with 50% of that treasure. There are ways to purchase protective “roadcase” NFTs' that help protect your tokens. The chupacabra still might get their hands on a lot of unprotected loot but that’s where the 150 rare “Archer Goat NFTs’ come in. They are hero goats with deadly bow skills and as a reward for killing chupacabra they take any leftover token spoils to build their entertainment empires. The Archer Goats are auctioned off after the main goats have minted out and can only be bought with STR tokens 60,000 minimum to be exact.

            At the completion of MoFo Goats Public Mint, Starwire will award each Goat 32,000 STR tokens. One year after the MoFo Goats completed public mint, allocated STR Tokens will yield over ten years. (with the option to reduce to 1 year via Roadcases)
        </p><br/>
        <ListSection items={[ 'All MoFo Goats including Archer Goats have a Chupacabra tax of 50%.', ' Roadcases can be purchased for Chupacabra protection.' ]} />

        
        {/* Roadcases Section */}
        <h3>Roadcases</h3>
        <p>Roadcases are ERC-1155 tokens on the Ethereum blockchain.</p><br/>
        <ListSection items={[ 'Can be minted for 1000 STR tokens.', 'Mint proceeds are distributed in the following fashion:' ]} />
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;‑   80% to STR/ETH Liquidity Pool Tokens sent to Treasury.<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;‑   20% distributed equally to Archer Goats.
        </p>
        <ListSection items={[ 'Offer wallet protection from Chupacabra.', 'Will reduce the taxed amount by 5% (20 Roadcases = zero chupacabra tax).',
            '10 Roadcases per Goat can be turned in to boost STR yield, reducing yield to 1 year.', 'Incur a 8.3% secondary sales royalty fee allocated 100% to DAO.' ]} />

        {/* Auction Section */}
        <h3>Auction</h3>
        <p>140 Archer Goats will be Auctioned off the next 280 days.<br/><br/>

            1 Archer Goat will be auctioned every 2 days at a reserve price of 60,000 STR. 
            In case the reserve is not met the Archer Goat will be handed to the Starwire team 
            for listing in Opensea for sale at the reserve price + 10% in ETH 
            (Converted at the exchange rate of the moment).<br/><br/>

            Proceeds from the Auction sales will distribute as followed:
        </p><br/>
        <ListSection items={[ '90% converted to STR/ETH Liquidity and added to the Goats DAO treasury.', '10% Distributed evenly to Archer Goats' ]} />

        {/* Secondary Sales Section */}
        <h3>Secondary Sales</h3>
        <p>MoFo Goats will incur a 8.3% royalty fee when traded on secondary marketplaces.<br/><br/>

            The 8.3% royalty fee will breakdown as follows:
        </p><br/>
        <ListSection items={[ '50% to DAO Treasury in the form of LP’s. (STR/ETH)', '50% to Starwire.' ]} />

        {/* Summary Section */}
        <h3>Summary</h3>
        <p>Goats DAO is ushering in Web3 Technology to creators all over the world. Leveraging the power 
            of social capital and Web3, each MoFo Goat is allocated STR tokens to empower holders to innovate 
            and create their dreams.<br/><br/>

            Featuring a Full ON CHAIN DAO that gives power back to holders, transparency, and governance over 
            the future of the DAO. Our vision is to bring a MoFo (motivated and focused) Think Tank of the 
            brightest minds most likely to succeed in Web3 entertainment based projects. This collection of 
            people would provide help, and solve problems for content creators and fans alike. The goats will 
            foster creation of new content and engage fans in new ways.<br/><br/>

            <b>** commercial rights subject to certain restrictions</b>
        </p><br/>
      </main>
    </div>
  );
}

export default GoatPaper;
