import React from 'react'
import axios from '../../services/AxiosInstance';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '@mui/material'
import { GoogleLogin } from '@react-oauth/google'

import './index.scss';
import { useAuth } from '../../services/AuthContext';
import { showToast } from '../../utils/Toasts/ToastPopup';
import useUserStore from '../../stores/userStore'

export default function AccessModal({isModalOpen, toggleModal}) {  
  const { setAuthStatus } = useAuth();
  
  const handleGoogleAccess = async (data) => {

    let response;
    try {
      response = await axios.post('/api/v1/user/auth', {
        type: "google",
        data: {
          credential: data.credential
        }
      });

      if (response && response.status === 200) {
        setAuthStatus(true);
      } 
    } catch (error) {
      console.error('Google sign-in error: ', error);
      showToast("Error completing Google sign-in", "error");
      setAuthStatus(false);
    } finally {
      // console.log('user: ', response.data);
      useUserStore.getState().setUserData(response.data);
      showToast("Login Successful!", "success")
      toggleModal();
    }

  }

  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal}>
     <ModalHeader toggle={toggleModal}>Access Portal</ModalHeader>
      <ModalBody>
      <div style={{display: 'flex', flexDirection: 'column' ,alignItems: 'center'}}>
      <GoogleLogin 
            onSuccess={handleGoogleAccess}
            onError={response => {
              console.log(response);
            }}
        />
        {/* <Button color='primary' variant='contained' style={{marginTop : '15px'}}>
          Wallet Connect
        </Button> */}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}