import React, { useState } from 'react';
import '../Home Sections/FAQSection.scss'

export default function FAQSection() {

    const [ activeIndex, setActiveIndex ] = useState(0);

    const questionsAnswers = [
        { question: 'How Can I Buy a Mofo Goat?', 
            answer: (
            <>
             The collection mint is sold out, but you can buy on the secondary market at <a href="https://opensea.io/collection/mofo-goats-dao" target="_blank" rel="noopener noreferrer">
                        OpenSea
                    </a>.
            </>
        )},
        { question: 'What Is the difference between Mofo Goat and an Archer Goat NFTs?', answer: 'Mofo Goats are a collection of 4,850 AI-generated NFT goats, while the Archer Goats are 150 unique hand-painted NFT goats created by world-renown rock and roll muralist Paul Archer, each Archer goat has a unique relationship to famous locations around the globe. All NFTs are living on the Ethereum Blockchain.' },
        { question: 'How about the rarities?', answer: 'Mofo Goat rarity list will be revealed after the OpenSea listing. Archer series goats have 10 base designs then each will have 1 of 150 unique famous location traits applied to it.' },
        { question: 'What are the royalties?', answer: '8.3% ( not counting opensea ) commission on secondary sales.' },
        { question: 'How many Mofo Goats are reserved?', answer: 'We reserved 3700 Mofo Goats for marketing events, giveaways, the long-term health of goats ecosystem as well as proven projects, leaders, and educators within financially distressed areas around the globe.' },
        { question: 'What are the benefits of owning a Goat?', answer: 'MoFo Goats are primarily focused on empowering creators around the globe to utilize Web3 Technologies and collaborate with each other. They can connect and collaborate in real life, get behind-the-scenes looks at movies or music being made by our community of content creators and industry professionals. NFT holders will have a voice and part in the governance of our GOATS DAO.; firstly by granting our Goat holders governance rights as well voting privileges for decisions that affect them directly - like what gets produced or where its sold! Goat holders enjoy commercial usage rights to their goats.' },
    ];

    const toggleFAQ = index => {
        setActiveIndex(activeIndex === index ? null : index)
    }
    return (
        <div className='section4'>
            <div className='faq-container'>
                <h2>Frequently Asked Questions</h2>
                <ul className='faq-list'>
                    {questionsAnswers.map((qa, index) => (
                        <li key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
                            <button className='faq-question' onClick={() => toggleFAQ(index)}>
                                {qa.question}
                                <span className='dropdown-icon'>{activeIndex === index ? 'V' : 'V'}</span>
                            </button>
                            <div className='faq-answer'>{qa.answer}</div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
