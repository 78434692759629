import { useField } from 'formik';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { TextField, InputAdornment } from '@mui/material';

// Custom text field component that uses Formik's useField hook
const CustomTextField = ({ label, disabled, ...props }) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
  
    // This line checks if the field should be shrunk
    const shouldShrink = !!field.value || meta.touched;
  
    return (
      <TextField
        {...field}
        label={label}
        fullWidth
        margin='normal'
        disabled={disabled}
        error={!!errorText}
        helperText={errorText}
        FormHelperTextProps={{ style: { color: 'red' } }}
        // Here you ensure the label shrinks if there is a value
        InputLabelProps={{ shrink: shouldShrink }}
        InputProps={{
          endAdornment: meta.error && meta.touched ? (
            <InputAdornment position='end'>
              <ErrorOutlineIcon style={{ color: 'red' }} />
            </InputAdornment>
          ) : null
        }}
      />
    );
  };

  export default CustomTextField;