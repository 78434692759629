import React from 'react';
import '../Home Sections/GoatStorySection.scss';

export default function GoatStorySection() {
  return (
    <div>
      <div className="section1">
        <img className="gs-image" src="./images/SIXER.jpg" alt="image" />
        <div className='gs-text'>
          <h2>Mofo Goats</h2>
          <br />
          <p> WHY WE EXIST
            <br />
            <br />
            WE BELIEVE independent creatives will do greater things together than apart.
            <br />
            <br />
            WE WILL break barriers and achieve goals previously unimagined.
            <br />
            <br />
            WE BELIEVE DAOs can be a much better way to organize, incentivize, and reward special teams of creative entrepreneurs focused on film, music, and metaworld transmedia experiences.
            <br />
            <br />
            WE FORESEE a coming revolution in the way we organize ourselves when working together in creative teams.
          </p>
        </div>
      </div>
    </div>
  )
}
