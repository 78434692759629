import React, { useState } from 'react';
import { Button, TextField, Select, MenuItem } from '@mui/material';

const CustomSocialsField = ({ label, socialOptions, onAddSocial }) => {
    const [selectedSocial, setSelectedSocial] = useState('');
    const [username, setUsername] = useState('');
   
  
    const handleSocialChange = (event) => {
      //console.log("social selected: ", event.target.value);
      setSelectedSocial(event.target.value);
    };
  
    const handleUsernameChange = (event) => {
      setUsername(event.target.value);
    };
  
    const handleAddClick = () => {
      if (selectedSocial && username) {
        onAddSocial(selectedSocial, username);
        setSelectedSocial('');
        setUsername('');
      }
    };
  
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Select
          value={selectedSocial}
          onChange={handleSocialChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Social Media Type' }}
          style={{ minWidth: 120 }} 
        >
          <MenuItem disabled value="">
            <em>Select</em>
          </MenuItem>
          {socialOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
  
        <TextField
          label={label}
          value={username}
          onChange={handleUsernameChange}
          variant="outlined"
          style={{ flex: 1 }}
        />
  
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddClick}
          disabled={!selectedSocial || !username}
        >
          ADD
        </Button>
      </div>
    );
  };

  export default CustomSocialsField;