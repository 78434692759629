import styled from 'styled-components';

const ResponsiveContainer = styled.div`
  background: radial-gradient(52.87% 134.18% at 50% 50.32%,
  #1f70ca 0%,
  #012b59 100%);
  width: 100vw;
  min-height: 100vh; // Ensure it covers at least the whole viewport height
  overflow-x: hidden; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Adjust this as needed
  align-items: center;
  margin: 0 auto;
  padding: 0; // Adjust padding as necessary
`;

export default ResponsiveContainer;
