import React, { useState, useEffect } from 'react';

import AccessModal from './Access Modal';
import RegisterModal from './Register Modal';

import 'bootstrap/dist/css/bootstrap.css';
import '../fonts/slackey.ttf';
import { useAuth } from '../services/AuthContext';
import '../components/Navbar.scss';

export default function Navbar() {
    const {isAuthenticated, setAuthStatus} = useAuth();
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    const [ showLoginModal, setShowLoginModal] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [isNavCollapsed, setIsNavCollapsed] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 992);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClose = () => {
        setShowLoginModal(false);
        setShowRegisterModal(false);
    };

    const handleShow = (modalType) => {
        if (modalType === 'login') {
            setShowLoginModal(true);
            setShowRegisterModal(false);
        } else if (modalType === 'register') {
            setShowRegisterModal(true);
            setShowLoginModal(false);
        }
    };

    useEffect(() => {
      //console.log('auth for reg: ', isAuthenticated);
      if (isAuthenticated) {
        console.log("Register: ", isAuthenticated)
        handleShow('register');
      }
    }, [isAuthenticated]);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const handleButtonClick = () => {
      if (isAuthenticated) {
          handleShow('register');
      } else {
          handleShow('login');
      }
  }

    return (
        <>
            <nav className="navbar navbar-expand-lg justify-content-between">
                {isMobileView && (
                    <>
                    <nav className="mobile navbar navbar-expand-lg justify-content-between">
                      {/* <a className="mobile navbar-brand" href="/">Icons</a> */}
                      {/* <div>
                        <a className="mobile navbar-brand" href="https://opensea.io/collection/mofo-goats-dao">
                          <img src='./images/icon/open_sea.png' alt="Opensea Icon" style={{ width: '32px', height: '32px' }} />
                        </a>
                        <a className="mobile navbar-brand" href="https://discord.com/invite/Yc8femuYk2">
                          <img className="discord-icon" src='./images/icon/discord.png' alt="Discord Icon" style={{ width: '32px', height: '32px' }}/>
                        </a>
                      </div> */}

                      <button className="mobile navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded={!isNavCollapsed} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                        <span className="mobile navbar-toggler-icon"></span>
                      </button>
              
                      <div  className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
                        <nav className="mobile nav nav-pills">
                        <div>
                        <a className="mobile navbar-brand" href="/">
                            <img className="logod-icon" src='./images/icon/goatsdao.png' alt="Logo Icon" style={{ width: '64px', height: '64px' }}/>
                            <span className="vertical-line">│</span>
                          </a>
                          <a className="mobile navbar-brand" href="https://opensea.io/collection/mofo-goats-dao">
                            <img src='./images/icon/open_sea.png' alt="Opensea Icon" style={{ width: '32px', height: '32px' }} />
                          </a>
                          <a className="mobile navbar-brand" href="https://discord.com/invite/Yc8femuYk2">
                            <img className="discord-icon" src='./images/icon/discord.png' alt="Discord Icon" style={{ width: '32px', height: '32px' }}/>
                          </a>
                        </div>
                          {/* <a className="mobile nav-item nav-link" href="/" style={{ color: 'yellow' }}>Home</a> */}
                          {/* <a className="mobile nav-item nav-link" href="/guest" style={{ color: 'yellow' }}>Guest</a> */}
                          {/* {isLoggedIn && <a className="nav-item nav-link" href="/private" style={{ color: 'yellow' }}>Private</a>} */}
                        </nav>
                        <nav className="mobile nav nav-pills">
                        <button className="btn nav-btn" type="button" onClick={handleButtonClick} 
                          style={{ color: '#104d92' }}>Register My Goat</button>
                          {/* <button className="mobile btn nav-btn" type="button" onClick={() => handleShow('register')} style={{ color: '#104d92' }}>Register</button>
                          <button className="mobile btn nav-btn" type="button" onClick={() => handleShow('login')} style={{ color: '#104d92' }}>Sign-in</button> */}
                        </nav>
                      </div>
                    </nav>
              
                    <AccessModal isModalOpen={showLoginModal} toggleModal={handleClose} />
                    <RegisterModal isModalOpen={showRegisterModal} toggleModal={handleClose} />
                  </>
                )}
                <nav className={isMobileView ? "stick-nav collapse navbar-collapse" : "navbar-collapse"}>
                  <nav className="nav nav-pills">
                  <div className="logo-social-container">
                        <a className="mobile navbar-brand" href="/">
                          <img src='./images/icon/goatsdao.png' alt="Logo Icon" style={{ width: '110px', height: '110px' }} />
                          <span className="vertical-line">│</span>
                        </a>
                        <a className="mobile navbar-brand" href="#" onClick={() => window.open('https://opensea.io/collection/mofo-goats-dao')}>
                          <img src='./images/icon/open_sea.png' alt="Opensea Icon" style={{ width: '62px', height: '62px' }} />
                        </a>
                        <a className="mobile navbar-brand" href="#" onClick={() => window.open('https://discord.com/invite/Yc8femuYk2')}>
                          <img className="discord-icon" src='./images/icon/discord.png' alt="Discord Icon" style={{ width: '62px', height: '62px' }}/>
                        </a>
                    </div>
                    {/* <a className="nav-item nav-link" href="/" style={{ color: 'yellow' }}>Home</a> */}
                    {/* <a className="nav-item nav-link" href="/guest" style={{ color: 'yellow' }}>Guest</a> */}
                    {/* {isLoggedIn && 
                      <a className="nav-item nav-link" href="/private" style={{ fontSize: '1.5rem' }}>Private</a>
                    } */}
                  </nav>
                  <nav className="nav nav-pills">
                    <button className="btn nav-btn" type="button" onClick={handleButtonClick} 
                      style={{ color: '#104d92' }}>Register My Goat</button>

                    {/* <button className="btn nav-btn" type="button" onClick={() => handleShow('register')} style={{ color: '#104d92' }}>Register</button>
                    <button className="btn nav-btn" type="button" onClick={() => handleShow('login')} style={{ color: '#104d92' }}>Sign-in</button> */}
                  </nav>
                </nav>
            </nav>

            <AccessModal isModalOpen={showLoginModal} toggleModal={handleClose} />
            <RegisterModal isModalOpen={showRegisterModal} toggleModal={handleClose} />
        </>
    );
}
