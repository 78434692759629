// Footer.js
import React from 'react';
import './Footer.scss';

const Footer = () => {
    return (
        <footer className="footer">
            <a href="https://opensea.io/collection/mofo-goats-dao" target="_blank" rel="noopener noreferrer">
                <img src="./images/icon/open_sea.png" alt="OpenSea" />
            </a>
            <a href="https://discord.com/invite/Yc8femuYk2" target="_blank" rel="noopener noreferrer">
                <img src="./images/icon/discord.png" alt="Discord" />
            </a>
            <a href="https://twitter.com/MofoGoats" target="_blank" rel="noopener noreferrer">
                <img src="./images/icon/twitter.png" alt="Twitter" />
            </a>
        </footer>
    );
};

export default Footer;
