import React from 'react'
import styled from 'styled-components'

import ResponsiveContainer from '../utils/ResponsiveContainer'
import 'pure-react-carousel/dist/react-carousel.es.css'
import '../fonts/slackey.ttf'
import '../fonts/angkor.ttf'
import '../fonts/regular.ttf'
import Navigation from '../components/Home Sections/Navigation'
import HomeSection from '../components/Home Sections/HomeSection'
import GoatStorySection from '../components/Home Sections/GoatStorySection'
import GoatPaperSection from '../components/Home Sections/GoatPaperSection'
import ConcertImageSection from '../components/Home Sections/ConcertImageSection'
import TeamSection from '../components/Home Sections/TeamSection'
import FAQSection from '../components/Home Sections/FAQSection'

export default function Home() {
  return (
    <>
        <Navigation />
        <HomeSection />
        <GoatStorySection />
        <GoatPaperSection />
        <ConcertImageSection />
        <TeamSection />
        <FAQSection />
    </>

  )
}